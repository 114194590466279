<template>
  <div>
    <!--<WidgetsDropdown/>-->
    <CModal title="Aviso!" color="danger" :show.sync="showAlert">
      {{ msg }}
      <template #footer>
        <CButton @click="showAlert = false" color="danger">OK</CButton>
      </template>
    </CModal>
    <CRow>
      <CCol sm="6" md="4">
        <CWidgetDropdown 
          color="info"
          text="Total" 
          :header="'R$ ' + String(resume.real.toFixed(2)).replace('.',',')"          
        />
      </CCol>
      <CCol sm="6" md="4">        
        <CWidgetDropdown 
          color="danger"
          text="Bloqueado"
          :header="'R$ ' + String(resume.blocked.toFixed(2)).replace('.',',')" 
        />
      </CCol>
      <CCol sm="6" md="4">
        <CWidgetDropdown 
          color="success" 
          text="Disponível" 
          :header="'R$ ' + String(resume.available.toFixed(2)).replace('.',',')" 
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h4 id="traffic" class="card-title mb-0">Outros Valores</h4>
                  <div class="small text-muted"></div>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CListGroup>
                <CListGroupItem
                  class="d-flex justify-content-between align-items-center"
                >
                  <b>Saldo Disponível para Transações</b>
                  <b>{{'R$ ' + String(resume.availableBalanceForTransactions.toFixed(2)).replace('.',',')}}</b>
                </CListGroupItem>                
                <CListGroupItem
                  class="d-flex justify-content-between align-items-center"
                >
                  <b>Cheque Especial</b>
                  <b>{{'R$ ' + String(resume.overdraft.toFixed(2)).replace('.',',')}}</b>
                </CListGroupItem>
                
              </CListGroup>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 id="traffic" class="card-title mb-0">Extrato</h4>
                <div class="small text-muted">(Periodo máximo 120 dias)</div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>            
            <CRow>
              <CCol sm="12" md="4">
                <CInput label="Data Inicial" type="date" v-model="dtStart" />
              </CCol>
              <CCol sm="12" md="4">
                <CInput label="Data Final" type="date" v-model="dtEnd" />
              </CCol>
              <CCol sm="12" md="4">
                <CButton type="reset" color="primary" style="margin-top:28px" @click="getStatement">
                  Filtrar
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-if="statement && statement.length > 0">
      <CCol sm="12">
        <CCard>
          <CDataTable
            class="mb-0 table-outline"
            hover
            head-color="light"
            :items="statement"
            :fields="tableFields"
          >
            <td slot="type" slot-scope="{item}">
              <div v-if="item.type==='D'" class="text-center">(-)</div>
              <div v-else class="text-center">(+)</div>
            </td>
            <td slot="amount" slot-scope="{item}">
              <div class="text-right">
                {{item.type==='D' ? '-':''}}{{ item.amount.toFixed(2).replace('.',',')}}
              </div>
            </td>
            <td slot="entryDate" slot-scope="{item}">
              {{ formatDate( Date.parse(item.entryDate), 'dd/mm/yyyy', true )       }}
              ás
              {{ formatDate( Date.parse(item.entryDate), 'HH:MM:ss', false )       }}
            </td>
            <td slot="creditDate" slot-scope="{item}">
              {{ formatDate( Date.parse(item.creditDate), 'dd/mm/yyyy', true )       }}
            </td>
            
          </CDataTable>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import functions from "@/functions";
//import ColorView from '../theme/ColorView.vue';
const axios = require("axios").default;
const dateFormat = require('dateformat');

export default {
  components: {},
  name: 'Dashboard',
  data () {
    return {
      selected: 'Month',
      showAlert: false,
      msg: '', 
      tableFields: [
        { key: 'type'       , label: 'Tipo'  },
        { key: 'entryDate'  , label: 'Data/Hora' },
        { key: 'description', label: 'Descrição' },
        { key: 'amount'     , label: 'Valor' },
        { key: 'creditDate' , label: 'Creditado Em:' },
      ],
      resume:{
        date: '',
        real: 0,
        available: 0,
        overdraft: 0,
        blocked: 0,
        availableBalanceForTransactions: 0,
      },
      statement:[],
      dtStart: '',
      dtEnd: '',
    }
  },
  created: function () {
    this.getData();
  },
  methods: {
    ...functions,
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },    
    getData: function () {
      const token = localStorage.getItem('user-token');
      axios({
        method: 'get',
        url: this.vhost('client/balance'),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.data.resume){
          this.resume=res.data.data.resume;
        }
        if (res.data.data.statement){
          this.statement=res.data.data.statement;
        }
      })
      .catch(this.onError);
    },
    getStatement(){
      var dtStart
      var dtEnd
      try {
        dtStart = new Date(this.dtStart);
        dtEnd = new Date(this.dtEnd);
        const difDay = ((dtEnd-dtStart) / (1000 * 3600 * 24)) + 1;
        if (difDay>120){
          return  this.onError('Periodo selecionado superior a 120 dias.');
        }
        if (difDay<1){
          return this.onError('Data selecionada não é válida.')
        }
      } catch (error) {
        return this.onError(error)
      }
      const token = localStorage.getItem('user-token');
      const config = {
        method: 'get',
        url: this.vhost('client/statement'),
        headers: { "Content-Type": "application/json","x-access-token": token},
        params:{
          dtStart: dateFormat(dtStart,'yyyy-mm-dd', true),
          dtEnd: dateFormat(dtEnd,'yyyy-mm-dd', true),
        }
      }
     
      axios(config)
      .then((res) => {
        this.statement = res.data.data.statement;
      })
      .catch(this.onError);
      
    },
    formatDate(d,format,utc){
      return dateFormat(d,format,utc)
    },
    onError(err){
      console.log('onError:', err);
      if (err.response && err.response.data) {
          if (err.response.data.auth===false) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-data');
            this.$router.push('/login');
          } else if (err.response && err.response.data.erro){
             this.setAlert(err.response.data.erro); 
          } else {
            this.setAlert(err.message);
          }
      } else if(typeof(err)==='string') { 
        this.setAlert(err);
      } else {
        this.setAlert(err.message);
      }
    },
    setAlert: function (message){
      this.msg=message;
      this.showAlert=(message !== undefined);
    },
  },
}
</script>
